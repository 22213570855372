import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import styled from '@emotion/styled'

// Contexts
import LangContext from '../../contexts/LangContext'

// Components
import { H4 } from '../Titles'

const Container = styled.div`
  margin-bottom: 6rem;
`

const InputContainer = styled.input`
  border-bottom: 1px solid #C5B99A;
  background-color: transparent;
  width: 100%;

  font-family: 'SangBleuSans';
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 140%;

  color: white;
  padding: 2rem 0;

  &::placeholder {
    font-weight: 200;
    font-size: 3.4rem;
  }

  &:hover {
  }

  &:focus {
    border-bottom: 2px solid #C5B99A;
  }
`

const TextAreaContainer = styled.textarea`
  border-bottom: 1px solid #C5B99A;
  background-color: transparent;
  width: 100%;
  resize: none;

  font-family: 'SangBleuSans';
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 140%;

  color: white;
  padding: 2rem 0;

  &:hover {
  }

  &::placeholder {
    font-weight: 200;
    font-size: 3.4rem;
  }

  &:focus {
    border-bottom: 2px solid #C5B99A;
  }
`

const ButtonContainer = styled.button`
  background-color: transparent;
  color: black;
  border: 1px solid #C5B99A;
  color: #C5B99A;
  outline: none;
  padding: 2rem 8rem;
  transition: all 200ms ease-in-out;

  H4 {
    opacity: 1;
    text-align: center;
  }

  &:hover {
    cursor: pointer;
    color: #101B1D;
    background-color: #C5B99A;
  }

  @media (max-width: 640px) {
    width: 100%;
  }
`

const ContactForm = () => {
  const [fieldErrors, setFieldErrors] = useState({})
  const { lang } = useContext(LangContext)

  const validationRules = {
    email: val => val && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val),
    message: val => !!val
  }

  const validate = () => {
    const errors = {}
    let hasErrors = false
    for (const key of Object.keys(inputs)) {
      errors[key] = !validationRules[key](inputs[key])
      hasErrors |= errors[key]
    }
    setFieldErrors(prev => ({ ...prev, ...errors }))
    return !hasErrors
  }

  const renderFieldError = field => {
    if (fieldErrors[field]) {
      return <p className='errorMsg'>Please enter a valid {field}.</p>
    }
  }

  useEffect(() => {
    // Only perform interactive validation after submit
    if (Object.keys(fieldErrors).length > 0) {
      validate()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Input Change Handling
  const [inputs, setInputs] = useState({
    email: '',
    message: ''
  })

  const handleOnChange = event => {
    event.persist()
    setInputs(prev => ({
      ...prev,
      [event.target.id]: event.target.value
    }))
  }

  // Server State Handling
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  })

  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    })
    if (ok) {
      setFieldErrors({})
      setInputs({
        email: '',
        message: ''
      })
    }
  }

  const handleOnSubmit = event => {
    event.preventDefault()
    if (!validate()) {
      return
    }
    setServerState({ submitting: true })
    axios({
      method: 'POST',
      url: 'https://formspree.io/xrgogbra',
      data: inputs
    })
      .then(r => {
        handleServerResponse(true, 'Thanks!')
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error)
      })
  }

  return (
    <>
      <form onSubmit={handleOnSubmit} noValidate>

        <Container>
          <H4 htmlFor='email'>Email:</H4>
          <InputContainer
            id='email'
            type='email'
            name='email'
            placeholder={lang === 'en' ? 'Your email' : 'Votre email'}
            onChange={handleOnChange}
            value={inputs.email}
            className={fieldErrors.email ? 'error' : ''}
          />
          {renderFieldError('email')}
        </Container>

        <Container>
          <H4 htmlFor='message'>Message:</H4>
          <TextAreaContainer
            style={{ height: '140px' }}
            id='message'
            name='message'
            type='text'
            placeholder={lang === 'en' ? 'Your message' : 'Votre message'}
            onChange={handleOnChange}
            value={inputs.message}
            className={fieldErrors.message ? 'error' : ''}
          />
          {renderFieldError('message')}
        </Container>

        <Container>
          <ButtonContainer type='submit' disabled={serverState.submitting}>
            <H4>{lang === 'en' ? 'Submit' : 'Envoyer'}</H4>
          </ButtonContainer>
        </Container>

        {serverState.status && (
          <p className={!serverState.status.ok ? 'errorMsg' : ''}>
            {serverState.status.msg}
          </p>
        )}
      </form>
    </>
  )
}

export default ContactForm
