import React, { useContext, useEffect } from 'react'
import styled from '@emotion/styled'
import { NavHashLink as NavLink } from 'react-router-hash-link'

// Contexts
import LangContext from '../../contexts/LangContext'

// Hooks
import useData from '../../hooks/useData'
import useDeviceDetect from '../../hooks/useDeviceDetect'

// Components
import { H1, H2, H4 } from '../../components/Titles'
import { Paragraph2 } from '../../components/Texts'
import { Loader } from '../../components/Loader'
import { Grid } from '../../components/Grids'
import Cover from '../../components/Cover'

// Assets
import icoOpen from '../../assets/icons/ico.note.svg'

const Header = styled(Grid)`
  height: calc(100vh - 150px);

   h1 {
    font-size: calc(34px + (124 - 34) * ((100vw - 300px) / (1600 - 300)));
    color: white;
  
    &:before {
      content: "—";
      padding-bottom: 2rem;
      display: block;
      font-size: 3.4rem;
    }

    &:after {
      content: "—";
      display: block;
      font-size: 3.4rem;
    }
   }

  @media (max-width: 640px) {
    height: calc(100vh - 250px);

    h1 {
      font-size: calc(48px + (124 - 48) * ((100vw - 300px) / (1600 - 300)));
    }
  }
`

const Question = styled(H2)`
  border-top: 1px solid rgba(197, 155, 154, 0.2);
  color: #C5B99A;
  padding-top: 4rem;
  font-size: 2.6rem;
`

const Link = styled(NavLink)`
  text-decoration: none;
  margin-top: 4rem;
  white-space: nowrap;
  color: #C5B99A;

  h4 {
    display: inline-block;
    opacity: 1;

    &:after {
      content: " ";
      vertical-align: bottom;
      display: inline-block;
      margin-left: 0.5rem;
      width: 24px;
      height: 24px;
      background-color: #C5B99A;
      -webkit-mask-image: url(${icoOpen});
      mask-image: url(${icoOpen});
    }

    &:hover {
      opacity: 0.6;
    }
  }

  @media (max-width: 640px) {
    margin-bottom: 10rem;
  }
`

const QandA = () => {
  const { lang } = useContext(LangContext)
  const [data, isLoaded] = useData('/QandA', lang)
  const { isMobile } = useDeviceDetect()

  const renderHTMLContent = (ref, element) => {
    return data.filter(d => d.ref === ref).map(d => d[element])
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const sentencesList = () => {
    return data.map((d, i) => {
      return (
        <Grid key={i}>
          {
            d.ref === 'question'
              ? <Question dangerouslySetInnerHTML={{ __html: d.content }} />
              : null
          }
          {
            d.ref === 'answer'
              ? <Paragraph2 dangerouslySetInnerHTML={{ __html: d.content }} />
              : null
          }
          {
            d.ref === 'answer' && d.route
              ? <Link to={`${d.route}`} activeClassName='selected'><H4 dangerouslySetInnerHTML={{ __html: d.label }} /></Link>
              : null
          }
        </Grid>
      )
    })
  }

  const render = () => {
    return (
      <Grid>
        <Cover text={renderHTMLContent('page-title-01', 'content')} type='text' />
        <Header justify='center'>
          <Grid align='end'><H1 dangerouslySetInnerHTML={{ __html: renderHTMLContent('page-title-01', 'content') }} /></Grid>
          <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('subtitle-01', 'content') }} style={{ padding: isMobile ? '0 4rem' : '0' }} />
        </Header>

        <Grid cols='1fr 2fr' rowGap='10vw' colGap='10vw' style={{ padding: isMobile ? '0 4rem 10rem 4rem' : '5vw 15vw 10vw 15vw' }}>
          <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-01', 'content') }} />
          {sentencesList()}
        </Grid>
      </Grid>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default QandA
