import React, { useContext } from 'react'
import styled from '@emotion/styled'

// Contexts
import LangContext from '../../contexts/LangContext'

// Hooks
import useData from '../../hooks/useData'
import useDeviceDetect from '../../hooks/useDeviceDetect'

// Components
import { H1 } from '../../components/Titles'
import { Paragraph2, Quote } from '../../components/Texts'
import { Loader } from '../../components/Loader'
import { Grid } from '../../components/Grids'

const SectionTitle = styled(H1)`
color: #859E92;

  &:before {
    content: "—";
    padding-bottom: 2rem;
    display: block;
    font-size: 4.2rem;
  }

  &:after {
    content: "—";
    display: block;
    font-size: 4.2rem;
  }
`

const Philosophy = () => {
  const { lang } = useContext(LangContext)
  const [data, isLoaded] = useData('/Philosophy', lang)
  const { isMobile } = useDeviceDetect()

  const renderHTMLContent = (ref, element) => {
    return data.filter(d => d.ref === ref).map(d => d[element])
  }

  const render = () => {
    return (
      <Grid rowGap='10vw'>
        <SectionTitle style={{ overflowX: isMobile ? 'hidden' : null }} dangerouslySetInnerHTML={{ __html: renderHTMLContent('section-title', 'content') }} />

        <Grid rowGap='4rem' pads='0 14vw'>
          <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-01', 'content') }} />
          <Quote dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-02', 'content') }} />
          <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-03', 'content') }} />
        </Grid>

      </Grid>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default Philosophy
