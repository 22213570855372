import React, { useState } from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'

// Components
import Nav from './components/Nav'
import Footer from './components/Footer'
import GlobalStyles from './components/GlobalStyles'

// Pages
import Career from './pages/Career'
import Home from './pages/Home'
import Services from './pages/Services'
import QandA from './pages/QandA'
import Contact from './pages/Contact'

// Contexts
import LangContext from './contexts/LangContext'

const App = () => {
  const [lang, setLang] = useState('fr')

  return (
    <Router>
      <GlobalStyles />

      <LangContext.Provider value={{ lang, setLang }}>
        <Nav />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/career' exact component={Career} />
          <Route path='/services' exact component={Services} />
          <Route path='/q-and-a' exact component={QandA} />
          <Route path='/contact' exact component={Contact} />
        </Switch>
        <Footer />
      </LangContext.Provider>
    </Router>
  )
}

export default App
