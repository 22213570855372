import React from 'react'
import LazyLoad from 'react-lazyload'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

// Components
import { PlaceHolder } from '../Loader'

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.height + 'px'};
  overflow: hidden;

  @media (max-width: 640px) {
    height: ${props => props.height / 1.8 + 'px'};
  }
`

const ImageElement = styled('div')(({ height, src, type = 'cover' }) =>
  css`
    position: absolute;
    width: 100%;
    height: ${height + 'px'};
    background: url(${src}) no-repeat center center;
    background-size: ${type || 'cover'};
    border-radius: 2px;

    @media 
        (-webkit-min-device-pixel-ratio:1.5),
        (min--moz-device-pixel-ratio:1.5),
        (-o-min-device-pixel-ratio:3/2),
        (min-resolution:1.5dppx)
    {
        background: url(${src}) no-repeat center center;
        background-size: ${type};
    }

    @media (max-width: 640px) {
      height: ${height / 1.8 + 'px'};
    }
  `
)

const Image = (props) => {
  return (
    <LazyLoad
      height={props.height}
      once
      placeholder={<PlaceHolder height={props.height} />}
    >
      <ImageContainer height={props.height}>
        <ImageElement
          {...props}
        />
      </ImageContainer>
    </LazyLoad>
  )
}

export default Image

Image.propTypes = {
  height: PropTypes.number.isRequired,
  type: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
}
