import React, { useContext, useEffect, useRef } from 'react'
import styled from '@emotion/styled'

// Contexts
import LangContext from '../../contexts/LangContext'

// Hooks
import useData from '../../hooks/useData'
import useDeviceDetect from '../../hooks/useDeviceDetect'

// Components
import Cover from '../../components/Cover'
import { Grid } from '../../components/Grids'
import { H1, H2, H3, H4 } from '../../components/Titles'
import { Paragraph2 } from '../../components/Texts'
import Video from '../../components/Video'
import { Loader } from '../../components/Loader'
import Philosophy from './Philosophy'
import Examples from './Examples'
import References from './References'

// Assets
import videoCover from './assets/video-cover.mp4'
import video01 from './assets/video-01.mp4'
import icoOpen from '../../assets/icons/ico.learnmore.svg'

const LearnMore = styled(H4)`
  z-index: 9;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  color: white;
  display: grid;
  justify-items: center;
  opacity: 1;
  cursor: s-resize;
  transition: all 200ms ease-in-out;
  padding-bottom: 8rem;

  &:hover {
    opacity: 0.6;
  }

  &:after {
    content: " ";
    vertical-align: bottom;
    display: inline-block;
    margin-top: 2rem;
    width: 24px;
    height: 24px;
    background-color: white;
    -webkit-mask-image: url(${icoOpen});
    mask-image: url(${icoOpen});
  }
`

const OverlappedText = styled.div`
  z-index: 1; 
  position: relative;
  left: -20rem;
  top: 10rem;

  @media (max-width: 640px) {
    left: 0;
    top: 0;
  }
`

const Home = () => {
  const { lang } = useContext(LangContext)
  const [data, isLoaded] = useData('/Home', lang)
  const { isMobile } = useDeviceDetect()

  const introRef = useRef(null)
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
  const executeScroll = () => scrollToRef(introRef)

  const renderHTMLContent = (ref, element) => {
    return data.filter(d => d.ref === ref).map(d => d[element])
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const render = () => {
    return (
      <Grid rowGap={isMobile ? '10rem' : '0'}>
        <Cover videoSrc={videoCover} />

        <Grid cols='repeat(1, 1fr)' justify='center' rowGap='6rem' height='calc(100vh - 10px)'>
          <Grid align='end'>
            <H3>Pierre Fenouillat</H3>
          </Grid>
          <Grid align='start' justify='center'>
            <H1 style={{ width: '12ch' }} dangerouslySetInnerHTML={{ __html: renderHTMLContent('section-title', 'content') }} />
          </Grid>
          <Grid align='center' justify='center'>
            <LearnMore onClick={executeScroll}>{lang === 'en' ? 'Learn More' : 'Voir plus'}</LearnMore>
          </Grid>
        </Grid>

        <Grid ref={introRef} cols='repeat(1, 1fr)' rowGap='4rem' style={{ padding: isMobile ? '0rem 4rem 8rem 4rem' : '35vh 25vw' }}>
          <H4 style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: renderHTMLContent('title-01', 'content') }} />
          <H2 style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: renderHTMLContent('excerpt-header', 'content') }} />
        </Grid>

        <Grid rowGap={isMobile ? '10rem' : '0'}>
          <Grid cols='repeat(2, 1fr)' col='auto' row='1 / 2'>
            <Video src={video01} height={470} autoPlay style={{ objectFit: 'cover', opacity: isMobile ? '1' : '0.8' }} />
            <OverlappedText style={{ padding: isMobile ? '10rem 4rem 0 4rem' : '0' }}>
              <H2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-01', 'content') }} />
            </OverlappedText>
          </Grid>

          <Grid cols='repeat(2, 1fr)' col='2/2' row='2 / 2' align='center' >
            <Paragraph2 style={{ padding: isMobile ? '0 4rem' : '12rem 12rem 0 0' }} dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-02', 'content') }} />
          </Grid>
        </Grid>

        <Grid style={{ padding: isMobile ? '4rem 4rem 10rem 4rem' : '10vw 15vw' }}>
          <Philosophy />
        </Grid>

        <Grid>
          <Examples />
        </Grid>

        <Grid style={{ padding: isMobile ? '4rem 4rem 10rem 4rem' : '10vw 15vw' }}>
          <References />
        </Grid>

      </Grid>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default Home
