import styled from '@emotion/styled'

export const H1 = styled.h1`
  font-family: 'SangBleuSans';
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  text-align: center;
  font-size: calc(34px + (98 - 34) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 200;
  line-height: 110%;
`

export const H2 = styled.h2`
  font-family: 'SangBleuSans';
  text-align: left;
  font-size: calc(21px + (34 - 21) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 200;
  line-height: 140%;
`

export const H3 = styled.h3`
  font-family: 'SangBleuSans';
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 140%;
`

export const H4 = styled.h4`
  font-family: 'Inter';
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  text-align: left;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 140%;
  opacity: 0.6;
`

export const H5 = styled.h5`
  font-family: 'SangBleuSans';
  font-size: 1.1rem;
  font-weight: 200;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  padding-bottom: 2rem;
  opacity: 0.4;
`
