import React, { useContext } from 'react'
import styled from '@emotion/styled'

// Components
import { Loader } from '../Loader'
import { Grid } from '../Grids'
import { NavLink } from 'react-router-dom'
import { H2 } from '../Titles'

// Hooks
import useData from '../../hooks/useData'

// Contexts
import LangContext from '../../contexts/LangContext'

// Assets
import icoClose from '../../assets/icons/ico.close.svg'
import icoBurgerMenu from '../../assets/icons/ico.burger-menu.svg'

const ToggleMenu = styled.div`
    z-index: 999;
    margin: 4rem;
    float: right;
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-color: white;
    -webkit-mask-image: ${({ open }) => open ? `url(${icoClose})` : `url(${icoBurgerMenu})`}};
    mask-image: ${({ open }) => open ? `url(${icoClose})` : `url(${icoBurgerMenu})`}};
`

const StyledMenu = styled(Grid)`
    overflow: hidden;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #121A1A;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
    height: 100vh;
    width: 100vw;
    text-align: left;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.2s ease-in-out;

    a {
        opacity: 1;
    }

    h2 {
        font-size: 3.4rem;
        padding: 1rem 0;
    }
`

const BurgerMenu = ({ open, setOpen, ...props }) => {
  const isExpanded = !!open

  document.body.classList.toggle('scrollable', !!open)

  return (
    <ToggleMenu
      aria-label='Toggle menu'
      aria-expanded={isExpanded}
      open={open}
      onClick={() => setOpen(!open)} {...props}
    />
  )
}

const Menu = ({ open, setOpen, ...props }) => {
  const { lang } = useContext(LangContext)
  const [data, isLoaded] = useData('/_menu', lang)

  const isHidden = !!open

  const linksList = data.filter(d => d.ref === 'nav.internal').map((d, i) => {
    return (
      <NavLink exact key={i} to={d.route} onClick={() => setOpen(!open)}><H2>{d.content}</H2></NavLink>
    )
  })

  const render = () => {
    return (
      <StyledMenu columns='1fr' align='center' justify='center' open={open} aria-hidden={!isHidden} {...props}>
        {linksList}
      </StyledMenu>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export { BurgerMenu, Menu }
