import React, { useContext, useEffect } from 'react'
import styled from '@emotion/styled'
import { NavHashLink as NavLink } from 'react-router-hash-link'

// Contexts
import LangContext from '../../contexts/LangContext'

// Hooks
import useData from '../../hooks/useData'
import useDeviceDetect from '../../hooks/useDeviceDetect'

// Components
import { H1, H2, H4 } from '../../components/Titles'
import { Paragraph2 } from '../../components/Texts'
import { Loader } from '../../components/Loader'
import { Grid } from '../../components/Grids'
import Video from '../../components/Video'
import Cover from '../../components/Cover'

// Assets
import video01 from './assets/video-01.mp4'
import video02 from './assets/video-02.mp4'
import video03 from './assets/video-03.mp4'
import video04 from './assets/video-04.mp4'
import video05 from './assets/video-05.mp4'
import icoOpen from '../../assets/icons/ico.note.svg'

const StickyMenu = styled(Grid)`
  position: sticky;
  top: 0px;
  z-index: 9;
  height: 550px;

  @media (max-width: 640px) {
    position: relative;
    height: 400px;
  }

  ul > li {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 1.4rem 0;
    
    h4 {
      opacity: 1;
    }
  }
`

const Header = styled(Grid)`
  height: calc(100vh - 150px);

   h1 {
    font-size: calc(34px + (124 - 34) * ((100vw - 300px) / (1600 - 300)));
    color: white;
  
    &:before {
      content: "—";
      padding-bottom: 2rem;
      display: block;
      font-size: 3.4rem;
    }

    &:after {
      content: "—";
      display: block;
      font-size: 3.4rem;
    }
   }

   @media (max-width: 640px) {
    height: calc(100vh - 250px);

    h1 {
      font-size: calc(48px + (124 - 48) * ((100vw - 300px) / (1600 - 300)));
    }
  }
`

const SectionTitle = styled(H2)`
  color: #859E92;
  padding-bottom: 4rem;

  &:after {
    padding-top: 2rem;
    content: "—";
    display: block;
    font-size: 3.4rem;
  }
`

const Link = styled(NavLink)`
  text-decoration: none;
  margin-top: 4rem;
  white-space: nowrap;
  color: #C5B99A;

  h4 {
    display: inline-block;
    opacity: 1;

    &:after {
      content: " ";
      vertical-align: bottom;
      display: inline-block;
      margin-left: 0.5rem;
      width: 24px;
      height: 24px;
      background-color: #C5B99A;
      -webkit-mask-image: url(${icoOpen});
      mask-image: url(${icoOpen});
    }

    &:hover {
      opacity: 0.6;
    }
  }
`

const Services = () => {
  const { lang } = useContext(LangContext)
  const [data, isLoaded] = useData('/Services', lang)
  const { isMobile } = useDeviceDetect()

  const renderHTMLContent = (ref, element) => {
    return data.filter(d => d.ref === ref).map(d => d[element])
  }

  const renderMenu = () => {
    return data.filter(d => d.ref.match(/^title-/gi)).map((d, i) => {
      return (
        <li key={i}>
          <NavLink to={`/services/#${d.route}`} activeClassName='selected'>
            <H4>{d.content}</H4>
          </NavLink>
        </li>
      )
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const render = () => {
    return (
      <Grid>
        <Cover text={renderHTMLContent('page-title-01', 'content')} type='text' />

        <Header justify='center'>
          <Grid align='end'><H1 dangerouslySetInnerHTML={{ __html: renderHTMLContent('page-title-01', 'content') }} /></Grid>
          <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('subtitle-01', 'content') }} style={{ padding: isMobile ? '0 4rem' : '0' }} />
        </Header>

        <Grid cols='1fr 3fr' colGap='5vw' rowGap='10vw'>

          <StickyMenu style={{ padding: isMobile ? '0 4rem 0 4rem' : '10vw 0 0 15vw' }}>
            <ul>
              {renderMenu()}
            </ul>
          </StickyMenu>

          <Grid>

            <Grid id={renderHTMLContent('title-02', 'route')} style={{ padding: isMobile ? '0 4rem 10rem 4rem' : '10vw 12vw' }}>
              <SectionTitle dangerouslySetInnerHTML={{ __html: renderHTMLContent('title-02', 'content') }} />
              <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-02', 'content') }} />
              <Link to='/contact'><H4>{lang === 'en' ? 'Book an appointment slot' : 'Réserver un rendez-vous'}</H4></Link>
            </Grid>

            <Grid>
              <Grid>
                <Video src={video05} height={500} style={{ objectFit: 'cover' }} autoPlay />
              </Grid>
              <Grid id={renderHTMLContent('title-03', 'route')} style={{ padding: isMobile ? '10rem 4rem' : '10vw 12vw' }}>
                <SectionTitle dangerouslySetInnerHTML={{ __html: renderHTMLContent('title-03', 'content') }} />
                <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-03', 'content') }} />
                <Link to='/contact'><H4>{lang === 'en' ? 'Book an appointment slot' : 'Réserver un rendez-vous'}</H4></Link>
              </Grid>
            </Grid>

            <Grid>
              <Grid>
                <Video src={video02} height={500} style={{ objectFit: 'cover' }} autoPlay />
              </Grid>
              <Grid id={renderHTMLContent('title-04', 'route')} style={{ padding: isMobile ? '10rem 4rem' : '10vw 12vw' }}>
                <SectionTitle dangerouslySetInnerHTML={{ __html: renderHTMLContent('title-04', 'content') }} />
                <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-04', 'content') }} />
                <Link to='/contact'><H4>{lang === 'en' ? 'Book an appointment slot' : 'Réserver un rendez-vous'}</H4></Link>
              </Grid>
            </Grid>

            <Grid>
              <Grid>
                <Video src={video04} height={500} style={{ objectFit: 'cover' }} autoPlay />
              </Grid>
              <Grid id={renderHTMLContent('title-05', 'route')} style={{ padding: isMobile ? '10rem 4rem' : '10vw 12vw' }}>
                <SectionTitle dangerouslySetInnerHTML={{ __html: renderHTMLContent('title-05', 'content') }} />
                <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-05', 'content') }} />
                <Link to='/contact'><H4>{lang === 'en' ? 'Book an appointment slot' : 'Réserver un rendez-vous'}</H4></Link>
              </Grid>
            </Grid>

            <Grid style={{ padding: isMobile ? '10rem 4rem' : '10vw 12vw' }} id={renderHTMLContent('title-06', 'route')}>
              <SectionTitle dangerouslySetInnerHTML={{ __html: renderHTMLContent('title-06', 'content') }} />
              <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-06', 'content') }} />
              <Link to='/contact'><H4>{lang === 'en' ? 'Ask for a quote' : 'Demander un devis'}</H4></Link>
            </Grid>

            <Grid>
              <Grid>
                <Video src={video01} height={500} style={{ objectFit: 'cover' }} autoPlay />
              </Grid>
              <Grid id={renderHTMLContent('title-07', 'route')} style={{ padding: isMobile ? '10rem 4rem' : '10vw 12vw' }}>
                <SectionTitle dangerouslySetInnerHTML={{ __html: renderHTMLContent('title-07', 'content') }} />
                <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-07', 'content') }} />
                <Link to='/contact'><H4>{lang === 'en' ? 'Ask for a quote' : 'Demander un devis'}</H4></Link>
              </Grid>
            </Grid>

            <Grid >
              <Grid>
                <Video src={video03} height={500} style={{ objectFit: 'cover' }} autoPlay />
              </Grid>
              <Grid id={renderHTMLContent('title-08', 'route')} style={{ padding: isMobile ? '10rem 4rem' : '10vw 12vw' }}>
                <SectionTitle dangerouslySetInnerHTML={{ __html: renderHTMLContent('title-08', 'content') }} />
                <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-08', 'content') }} />
                <Link to='/contact'><H4>{lang === 'en' ? 'Ask for a quote' : 'Demander un devis'}</H4></Link>
              </Grid>
            </Grid>

          </Grid>

        </Grid>
      </Grid>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default Services
