import React, { useContext } from 'react'
import styled from '@emotion/styled'

// Contexts
import LangContext from '../../contexts/LangContext'

// Hooks
import useData from '../../hooks/useData'
import useDeviceDetect from '../../hooks/useDeviceDetect'

// Components
import { H1 } from '../../components/Titles'
import { Paragraph2 } from '../../components/Texts'
import { Loader } from '../../components/Loader'
import { Grid } from '../../components/Grids'
import Image from '../../components/Image'

// Assets
import img01 from './assets/img-01.png'
import img02 from './assets/img-02.png'
import img03 from './assets/img-03.png'

const SectionTitle = styled(H1)`
color: #859E92;

  &:before {
    content: "—";
    padding-bottom: 2rem;
    display: block;
    font-size: 4.2rem;
  }

  &:after {
    content: "—";
    display: block;
    font-size: 4.2rem;
  }
`

const Photo = styled(Image)`
  opacity: 1;
`

const Link = styled.a`
  color: #C5B99A;
  opacity: 1;

  &:hover {
    text-decoration: none;
    color: white;
  }
`

const References = () => {
  const { lang } = useContext(LangContext)
  const [data, isLoaded] = useData('/References', lang)
  const { isMobile } = useDeviceDetect()

  const renderHTMLContent = (ref, element) => {
    return data.filter(d => d.ref === ref).map(d => d[element])
  }

  const referencesList1 = () => {
    return data.filter(d => d.ref === 'reference-01').map((d, i) => {
      return (
        <li key={i}><Link href={d.route} target='_blank' rel='noopener noreferrer'><Paragraph2>{d.content}</Paragraph2></Link></li>
      )
    })
  }

  const referencesList2 = () => {
    return data.filter(d => d.ref === 'reference-02').map((d, i) => {
      return (
        <li key={i}><Link href={d.route} target='_blank' rel='noopener noreferrer'><Paragraph2>{d.content}</Paragraph2></Link></li>
      )
    })
  }

  const referencesList3 = () => {
    return data.filter(d => d.ref === 'reference-03').map((d, i) => {
      return (
        <li key={i}><Link href={d.route} target='_blank' rel='noopener noreferrer'><Paragraph2>{d.content}</Paragraph2></Link></li>
      )
    })
  }

  const render = () => {
    return (
      <Grid cols='repeat(1, 1fr)' rowGap='10vw'>

        <Grid cols='repeat(1, 1fr)'>
          <SectionTitle dangerouslySetInnerHTML={{ __html: renderHTMLContent('title-01', 'content') }} />
        </Grid>

        <Grid cols='repeat(3, 1fr)' colGap='6rem' rowGap='10rem'>

          <Grid rowGap='4rem'>
            <Photo src={img01} height={500} alt='Pierre Fenouillat – References' />
            <Paragraph2 style={{ minHeight: isMobile ? '100px' : '200px' }} dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-01', 'content') }} />
            <Grid align='start' style={{ minHeight: isMobile ? '100px' : '500px' }}><ul>{referencesList1()}</ul></Grid>
          </Grid>

          <Grid rowGap='4rem'>
            <Photo src={img02} height={500} alt='Pierre Fenouillat – References' />
            <Paragraph2 style={{ minHeight: isMobile ? '100px' : '200px' }} dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-02', 'content') }} />
            <Grid align='start' style={{ minHeight: isMobile ? '100px' : '500px' }}><ul>{referencesList2()}</ul></Grid>
          </Grid>

          <Grid rowGap='4rem'>
            <Photo src={img03} height={500} alt='Pierre Fenouillat – References' />
            <Paragraph2 style={{ minHeight: isMobile ? '100px' : '200px' }} dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-03', 'content') }} />
            <Grid align='start' style={{ minHeight: isMobile ? '100px' : '500px' }}><ul>{referencesList3()}</ul></Grid>
          </Grid>

        </Grid>

      </Grid>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default References
