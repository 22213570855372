import React, { useContext, useEffect } from 'react'
import styled from '@emotion/styled'

// Contexts
import LangContext from '../../contexts/LangContext'

// Hooks
import useData from '../../hooks/useData'
import useDeviceDetect from '../../hooks/useDeviceDetect'

// Components
import { H1 } from '../../components/Titles'
import { Paragraph2, Quote } from '../../components/Texts'
import { Loader } from '../../components/Loader'
import { Grid } from '../../components/Grids'
import Cover from '../../components/Cover'
import Image from '../../components/Image'

// Assets
import img01 from './assets/img-01.png'
import img02 from './assets/img-02.png'

const Header = styled(Grid)`
  height: calc(100vh - 150px);

   h1 {
    font-size: calc(34px + (124 - 34) * ((100vw - 300px) / (1600 - 300)));
    color: white;
  
    &:before {
      content: "—";
      padding-bottom: 2rem;
      display: block;
      font-size: 3.4rem;
    }

    &:after {
      content: "—";
      display: block;
      font-size: 3.4rem;
    }
   }

  @media (max-width: 640px) {
    height: calc(100vh - 250px);

    h1 {
      font-size: calc(48px + (124 - 48) * ((100vw - 300px) / (1600 - 300)));
    }
  }
`

const Career = () => {
  const { lang } = useContext(LangContext)
  const [data, isLoaded] = useData('/Career', lang)
  const { isMobile } = useDeviceDetect()

  const renderHTMLContent = (ref, element) => {
    return data.filter(d => d.ref === ref).map(d => d[element])
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const render = () => {
    return (
      <Grid rowGap='10vw'>
        <Cover text={renderHTMLContent('page-title-01', 'content')} type='text' />

        <Header justify='center'>
          <Grid align='end'><H1 dangerouslySetInnerHTML={{ __html: renderHTMLContent('page-title-01', 'content') }} /></Grid>
          <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('subtitle-01', 'content') }} style={{ padding: isMobile ? '0 4rem' : '0' }} />
        </Header>

        <Grid pads='0 15vw'>

          <Grid>
            <Grid rowGap='4rem' style={{ padding: isMobile ? '0 4rem 10rem 4rem' : '0 15vw 10vw 15vw' }}>
              <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-01', 'content') }} />
              <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-02', 'content') }} />
            </Grid>
          </Grid>

          <Grid>
            <Grid>
              <Image src={img01} height={600} alt='Pierre Fenouillat – Career' />
            </Grid>
            <Grid rowGap='4rem' style={{ padding: isMobile ? '10rem 4rem 0 4rem' : '10vw 15vw 0 15vw' }}>
              <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-03', 'content') }} />
              <Quote style={{ fontSize: isMobile ? '2.4rem' : null }} dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-04', 'content') }} />
            </Grid>
          </Grid>

          <Grid rowGap='4rem' style={{ padding: isMobile ? '5rem 4rem 10rem 4rem' : '5vw 15vw 10vw 15vw' }}>
            <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-05', 'content') }} />
            <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-06', 'content') }} />
          </Grid>

          <Grid>
            <Grid>
              <Image src={img02} height={600} alt='Pierre Fenouillat – Career' />
            </Grid>
            <Grid rowGap='4rem' style={{ padding: isMobile ? '10rem 4rem' : '10vw 15vw' }}>
              <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-07', 'content') }} />
              <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-08', 'content') }} />
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default Career
