import React from 'react'
import { Global, css } from '@emotion/core'

// Constants
// import { mediumGreen, darkGreen, pureWhite } from '../../constants'

// Fonts
import '../../assets/fonts/inter/inter.css'
import '../../assets/fonts/sang-bleu-sans/sang-bleu-sans.css'

const GlobalStyles = () => {
  const style = css`
    ::selection {
      background: #C5B99A;
      text-shadow: none;
      color: white;
    }
    ::-webkit-scrollbar {
      width: 10px;

      @media only screen 
        and (min-device-width: 375px) 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2) { 
          display:none;
          width: 0px;
          background: transparent;
      }
    }
    ::-webkit-scrollbar-thumb {
      background: #C5B99A;
    }
    ::-webkit-scrollbar-track {
      background: linear-gradient(180deg, #0C1517 25%, #050A0B 100%);
    }
    html {
      font-size: 62.5%;
      line-height: 1.15; /* 1 */
      -webkit-text-size-adjust: 100%; /* 2 */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      scroll-behavior: smooth;
    }
    body {
      margin: 0;
      line-height: 1;
      font-family: 'Inter';
      font-weight: 400;
      font-size: 1.6rem;
      background: linear-gradient(180deg, #050A0B 25%, #0C1517 100%);
      color: white;
      margin: 0 auto;

      @media only screen 
      and (min-device-width: 375px) 
      and (max-device-width: 667px) 
      and (-webkit-min-device-pixel-ratio: 2) { 
        overflow-y: scroll;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
      }
    }
    body.scrollable {
      overflow: hidden;
    }
    main {
      display: block;
    }
    h1, h2, h3, h4, h5, p {
      padding: 0;
      margin: 0;
    }
    hr {
      box-sizing: content-box; /* 1 */
      height: 0; /* 1 */
      overflow: visible; /* 2 */
    }
    pre {
      font-family: monospace, monospace; /* 1 */
      font-size: 1em; /* 2 */
    }
    a {
      background-color: transparent;
      text-decoration: none;
      color: white;

      &:hover {
        text-decoration: underline;
      }
    }
    abbr[title] {
      border-bottom: none; /* 1 */
      text-decoration: underline; /* 2 */
      text-decoration: underline dotted; /* 2 */
    }
    b,
    strong {
      font-weight: 400;
      color: #C5B99A;
    }
    p {
      a {
        text-decoration: none;
        color: #C5B99A;
        transition: all 200ms ease-in-out;

        &:hover {
          text-decoration: none;
          opacity: 0.5;
          color: white;
        }
      }
      b, strong {
        font-weight: 800;
        color: white;
      }
    }
    code,
    kbd,
    samp {
      font-family: monospace, monospace; /* 1 */
      font-size: 1em; /* 2 */
    }
    small {
      font-size: 80%;
    }
    sub,
    sup {
      font-size: 75%;
      line-height: 0;
      position: relative;
      vertical-align: baseline;
    }

    sub {
      bottom: -0.25em;
    }

    sup {
      top: -0.5em;
    }
    img {
      border-style: none;
    }
    button,
    input,
    optgroup,
    select,
    textarea {
      font-family: inherit; /* 1 */
      font-size: 100%; /* 1 */
      line-height: 1.15; /* 1 */
      margin: 0; /* 2 */
      outline: none;
      border: 0;
    }
    button,
    input { /* 1 */
      overflow: visible;
    }
    button,
    select { /* 1 */
      text-transform: none;
    }
    button,
    [type="button"],
    [type="reset"],
    [type="submit"] {
      -webkit-appearance: button;
    }
    button::-moz-focus-inner,
    [type="button"]::-moz-focus-inner,
    [type="reset"]::-moz-focus-inner,
    [type="submit"]::-moz-focus-inner {
      border-style: none;
      padding: 0;
    }
    button:-moz-focusring,
    [type="button"]:-moz-focusring,
    [type="reset"]:-moz-focusring,
    [type="submit"]:-moz-focusring {
      outline: 1px dotted ButtonText;
    }
    fieldset {
      padding: 0.35em 0.75em 0.625em;
    }
    legend {
      box-sizing: border-box; /* 1 */
      color: inherit; /* 2 */
      display: table; /* 1 */
      max-width: 100%; /* 1 */
      padding: 0; /* 3 */
      white-space: normal; /* 1 */
    }
    progress {
      vertical-align: baseline;
    }
    textarea {
      overflow: auto;
    }
    [type="checkbox"],
    [type="radio"] {
      box-sizing: border-box; /* 1 */
      padding: 0; /* 2 */
    }
    [type="number"]::-webkit-inner-spin-button,
    [type="number"]::-webkit-outer-spin-button {
      height: auto;
    }
    [type="search"] {
      -webkit-appearance: textfield; /* 1 */
      outline-offset: -2px; /* 2 */
    }
    [type="search"]::-webkit-search-decoration {
      -webkit-appearance: none;
    }
    ::-webkit-file-upload-button {
      -webkit-appearance: button; /* 1 */
      font: inherit; /* 2 */
    }
    details {
      display: block;
    }
    summary {
      display: list-item;
    }
    template {
      display: none;
    }
    [hidden] {
      display: none;
    }
    li, ol, ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    em {
      font-style: normal;
      opacity: 0.5;
    }
  `

  return (
    <Global styles={style}/>
  )
}

export default GlobalStyles
