import React, { useContext } from 'react'
import styled from '@emotion/styled'

// Components
import { H4 } from '../../components/Titles'

// Contexts
import LangContext from '../../contexts/LangContext'

// Styling
const Button = styled.button`
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.8rem 2rem;
  border-radius: 100px;

  &:hover {
    cursor: pointer;
    background-color: #C5B99A;
    color: #0C1517;
  }

  @media (max-width: 640px) {
    margin-right: 2rem;
  }

  h4 {
    opacity: 1;
    font-size: 1.3rem;
  }
`

const LangSelector = () => {
  const { lang, setLang } = useContext(LangContext)

  return (
    <div>
      <Button onClick={() => setLang(lang === 'fr' ? 'en' : 'fr')}>
        <H4>{lang === 'fr' ? 'En' : 'Fr'}</H4>
      </Button>
    </div>
  )
}

export default LangSelector
