import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'

// Contexts
import LangContext from '../../contexts/LangContext'

// Hooks
import useData from '../../hooks/useData'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import useModal from '../../hooks/useModal'

// Components
import { H4 } from '../Titles'
import { Paragraph1, Paragraph2 } from '../Texts'
import { Loader } from '../Loader'
import { Grid } from '../Grids'
import ContactForm from '../ContactForm'
import Colophon from '../Colophon'

// Assets
import logo from '../../assets/icons/logo.svg'
import icoLinkedIn from '../../assets/icons/ico.LinkedIn.svg'
import icoInstagram from '../../assets/icons/ico.Instagram.svg'
import icoFacebook from '../../assets/icons/ico.Facebook.svg'

const Logo = styled.div`
  &:before {
    opacity: 0.5;
    content: " ";
    display: inline-block;
    width: 44px;
    height: 68px;
    background-color: white;
    background: url(${logo}) no-repeat bottom left;
  }
`

const FormContainer = styled(Grid)`
  background-color: #101B1D;
  color: white;
`

const ColophonContainer = styled(Grid)`
  opacity: 0.6;
  padding: 4rem;
`

const ExternalLinksContainer = styled.ul`
  .LinkedIn {
    display: inline-block;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      content: " ";
      vertical-align: middle;
      display: inline-block;
      margin-left: 0.5rem;
      width: 24px;
      height: 24px;
      background-color: #C5B99A;
      -webkit-mask-image: url(${icoLinkedIn});
      mask-image: url(${icoLinkedIn});
    }
  }

  .Instagram {
    display: inline-block;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      content: " ";
      vertical-align: middle;
      display: inline-block;
      margin-left: 0.5rem;
      width: 24px;
      height: 24px;
      background-color: #C5B99A;
      -webkit-mask-image: url(${icoInstagram});
      mask-image: url(${icoInstagram});
    }
  }

  .Facebook {
    display: inline-block;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      content: " ";
      vertical-align: middle;
      display: inline-block;
      margin-left: 0.5rem;
      width: 24px;
      height: 24px;
      background-color: #C5B99A;
      -webkit-mask-image: url(${icoFacebook});
      mask-image: url(${icoFacebook});
    }
  }
`

const Footer = () => {
  const { lang } = useContext(LangContext)
  const [contactData, isLoaded] = useData('/Footer', lang)
  const { isMobile } = useDeviceDetect()
  const { isShowing, toggle } = useModal()

  const [linksData] = useData('/_menu', lang)

  const internalLinksList = linksData.filter(d => d.ref === 'nav.internal').map((d, i) => {
    return (
      <li key={i}>
        <NavLink exact to={d.route}><Paragraph2>{d.content}</Paragraph2></NavLink>
      </li>
    )
  })

  const externalLinksList = linksData.filter(d => d.ref === 'nav.external').map((d, i) => {
    return (
      <li key={i}>
        <a href={d.route} target='_blank' rel='noopener noreferrer'><Paragraph2 className={d.content}>{d.content}</Paragraph2></a>
      </li>
    )
  })

  const renderHTMLContent = (ref, element) => {
    return contactData.filter(d => d.ref === ref).map(d => d[element])
  }

  const render = () => {
    return (
      <Grid>

        <FormContainer style={{ padding: isMobile ? '8rem 4rem' : '10vw 10vw 10vw 15vw' }} margin={isMobile ? '0' : '0 15vw 0 0'} cols='1fr 2fr' colGap='10vw'>
          <div style={{ padding: isMobile ? '0 0 8rem 0' : '0' }}>
            <H4 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-01', 'content') }} style={{ marginBottom: '4rem' }} />
            <Paragraph1 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-02', 'content') }} />
          </div>
          <ContactForm />
        </FormContainer>

        <Grid style={{ padding: isMobile ? '8rem 4rem' : '10vw 15vw' }} cols='2fr 1fr 1fr'>
          <Grid rowGap='4rem'>
            <Logo />
            <Paragraph1 dangerouslySetInnerHTML={{ __html: renderHTMLContent('address', 'content') }} />
          </Grid>
          <ul style={{ padding: isMobile ? '8rem 0' : '0' }}>{internalLinksList}</ul>
          <ExternalLinksContainer>{externalLinksList}</ExternalLinksContainer>
        </Grid>

        <ColophonContainer style={{ padding: '4rem' }} cols='repeat(2, 1fr)'>
          <Paragraph1>Copyright Pierre Fenouillat – {new Date().getFullYear()}</Paragraph1>
          <Paragraph1 onClick={toggle} style={{ justifySelf: isMobile ? 'left' : 'right', cursor: 'pointer', textDecoration: 'underline' }}>Mentions</Paragraph1>
          <Colophon isShowing={isShowing} hide={toggle} />
        </ColophonContainer>

      </Grid>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default Footer
