import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

// Assets
import SangBleuSansLight from './assets/fonts/sang-bleu-sans/SangBleuSans-Light-WebS.woff'
import SangBleuSansRegular from './assets/fonts/sang-bleu-sans/SangBleuSans-Regular-WebS.woff'

async function loadFonts () {
  const SangBleuSansL = new FontFace('SangBleuSans', `url(${SangBleuSansLight})`)
  const SangBleuSansR = new FontFace('SangBleuSans', `url(${SangBleuSansRegular})`)

  await SangBleuSansL.load()
  await SangBleuSansR.load()

  document.fonts.add(SangBleuSansL)
  document.fonts.add(SangBleuSansR)

  ReactDOM.render(<App />, document.querySelector('#root'))
}

loadFonts()
