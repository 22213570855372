import React, { useContext } from 'react'
import styled from '@emotion/styled'

// Contexts
import LangContext from '../../contexts/LangContext'

// Hooks
import useData from '../../hooks/useData'
import useDeviceDetect from '../../hooks/useDeviceDetect'

// Components
import { H2, H4 } from '../../components/Titles'
import { Paragraph2, Paragraph3 } from '../../components/Texts'
import { Loader } from '../../components/Loader'
import { Grid } from '../../components/Grids'

// Assets
import icoOpen from '../../assets/icons/ico.note.svg'

const ExamplesContainer = styled(Grid)`
  background-color: #F2EEE3;
  color: black;

  @media (max-width: 1400px) {
    grid-column-gap: 4vw;
  }
`

const ExampleContainer = styled(Paragraph3)`
  em {
    font-size: 1.3rem;
    display: block;
    line-height: 1.4;
    opacity: 1;

    &:after {
      content: "—";
      display: block;
      font-size: 1.5rem;
      padding: 1rem 0;
    }
  }
`

const StickyContainer = styled.div`
  position: sticky;
  top: 100px;
  z-index: 9;
  height: 300px;

  h4 {
    margin-bottom: 4rem;
  }

  @media (max-width: 640px) {
    position: relative;
  }
`

const GridContainer = styled(Grid)`
  @media (max-width: 1400px) {
    grid-column-gap: 2vw;
  }
`

const LiContainer = styled.li`
  display: grid;  
  text-align: center;
  justify-items: center;
`

const Link = styled.a`
  text-decoration: none;
  margin-top: 4rem;
  white-space: nowrap;
  color: #8A6442;

  h4 {
    display: inline-block;
    opacity: 1;

    &:after {
      content: " ";
      vertical-align: bottom;
      display: inline-block;
      margin-left: 0.5rem;
      width: 24px;
      height: 24px;
      background-color: #8A6442;
      -webkit-mask-image: url(${icoOpen});
      mask-image: url(${icoOpen});
    }

    &:hover {
      opacity: 0.6;
    }
  }
`

const SectionTitle = styled(H2)`
  padding-bottom: 4rem;
  font-size: 2.6rem;

  &:after {
    padding-top: 2rem;
    content: "—";
    display: block;
    font-size: 3.4rem;
  }
`

const Examples = () => {
  const { lang } = useContext(LangContext)
  const [data, isLoaded] = useData('/Examples', lang)
  const { isMobile } = useDeviceDetect()

  const renderHTMLContent = (ref, element) => {
    return data.filter(d => d.ref === ref).map(d => d[element])
  }

  const examplesList = () => {
    return data.filter(d => d.ref === 'example').map((d, i) => {
      return (
        <LiContainer key={i} style={{ gridAutoRows: 'min-content', padding: isMobile ? '6rem 0' : '0' }}>
          <ExampleContainer dangerouslySetInnerHTML={{ __html: d.content }} />
          <Link href={d.route} target='_blank' rel='noopener noreferrer'><H4>{d.label}</H4></Link>
        </LiContainer>
      )
    })
  }

  const render = () => {
    return (
      <ExamplesContainer cols='1fr 3fr' colGap='10vw' rowGap='10vw' style={{ padding: isMobile ? '10rem 4rem' : '10vw 15vw' }}>

        <StickyContainer style={{ padding: isMobile ? '0 0 10rem 0' : '0' }}>
          <SectionTitle dangerouslySetInnerHTML={{ __html: renderHTMLContent('title-01', 'content') }} />
          <Paragraph2 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-01', 'content') }} />
        </StickyContainer>

        <GridContainer cols='1fr 1fr' rowGap='10vw' colGap='10vw'>
          {examplesList()}
        </GridContainer>

      </ExamplesContainer>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default Examples
