import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

// Components
import { H4 } from '../Titles'

const LoaderContainer = styled.div`
  display: grid;
  width: 100vW;
  height: 100vH;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #101B1D;
  color: white;
  place-items: center;
`

const PlaceHolderContainer = styled.div`
  width: 100%;
  height: ${props => props.height + 'px'};
  background-color: #101B1D;
`

const SvgAnimated = styled.svg`
  align-self: end;
  padding: 4rem;
  
  .animPath {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: dash 1000ms ease-in-out infinite;
    animation-direction: alternate-reverse;
    animation-fill-mode: both;
  }
  .delay1 {
    animation-delay: 200ms;
  }
  .delay2 {
    animation-delay: 200ms;
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
`

const Svg = () => {
  return (
    <SvgAnimated width='58px' height='85px' viewBox='0 0 58 85'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' transform='translate(-1232.000000, -251.000000)'>
        <g transform='translate(1233.000000, 252.000000)' stroke='#C5B99A' strokeWidth='1.65'>
          <g transform='translate(0.549020, 0.000000)'>
            <g id='logo-pf'>
              <ellipse className='animPath delay1' cx='13.7254902' cy='69.1666667' rx='13.7254902' ry='13.8333333' />
              <path className='animPath delay2' d='M27.4509804,27.6666667 L13.7254902,27.6666667 C6.14511128,27.6666667 0,33.860061 0,41.5 C0,49.139939 6.14511128,55.3333333 13.7254902,55.3333333' />
              <path className='animPath delay1' d='M24.7058824,27.6666667 C17.1255034,27.6666667 10.9803922,33.860061 10.9803922,41.5 C10.9803922,49.139939 17.1255034,55.3333333 24.7058824,55.3333333 L24.7058824,55.3333333' />
              <ellipse className='animPath delay2' cx='24.7058824' cy='69.1666667' rx='13.7254902' ry='13.8333333' />
              <path className='animPath delay1' d='M38.4313725,27.6666667 L41.1764706,27.6666667 C48.7568495,27.6666667 54.9019608,21.4732724 54.9019608,13.8333333 C54.9019608,6.19339429 48.7568495,0 41.1764706,0 C33.5960917,0 27.4509804,6.19339429 27.4509804,13.8333333' />
              <path className='animPath delay1' d='M49.4131029,2.76666675 C43.3474616,2.76666667 38.4313725,7.7213821 38.4313725,13.8333333' strokeLinejoin='bevel' />
              <path className='animPath delay2' d='M21.9607843,41.7766667 L54.9019608,41.7766667' strokeLinecap='square' />
              <path className='animPath delay2' d='M38.4313725,13.8333333 L38.4313725,69.1666667 M27.4509804,69.1666667 L27.4509804,13.8333333 L27.4509804,13.8333333' />
            </g>
          </g>
        </g>
      </g>
    </SvgAnimated>
  )
}

export const Loader = () => {
  return (
    <LoaderContainer>
      <Svg />
      <H4 style={{ alignSelf: 'start', color: '#C5B99A', opacity: '1' }}>Loading ...</H4>
    </LoaderContainer>
  )
}

export const PlaceHolder = (props) => {
  return (
    <PlaceHolderContainer {...props} />
  )
}

PlaceHolder.propTypes = {
  highlight: PropTypes.bool,
  height: PropTypes.number.isRequired
}
