import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import * as THREE from 'three'
import { Canvas, useThree, useFrame } from 'react-three-fiber'
import SimplexNoise from 'simplex-noise'

// Hooks
import useDeviceDetect from '../../hooks/useDeviceDetect'

const CanvasContainer = styled.div`
  position: absolute;
  width: 98vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
`

const changeUvs = (geometry, unitX, unitY, offsetX, offsetY) => {
  const faceVertexUvs = geometry.faceVertexUvs[0]

  for (let i = 0; i < faceVertexUvs.length; i++) {
    const uvs = faceVertexUvs[i]

    for (let j = 0; j < uvs.length; j++) {
      const uv = uvs[j]
      uv.x = (uv.x + offsetX) * unitX
      uv.y = (uv.y + offsetY) * unitY
    }
  }
}

const loadTexture = (video, canvas, text, type, isMobile) => {
  let texture = null

  if (type === 'text') {
    const fontSize = window.innerWidth / (isMobile ? 0.5 : 2) + 'px'
    canvas.current.width = 1920 * 2
    canvas.current.height = 1080 * 2
    const ctx = canvas.current.getContext('2d')
    ctx.scale(2, 2)
    ctx.fillStyle = '#3E5658'
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.font = `${fontSize} SangBleuSans`
    ctx.fillText(text.toString().toUpperCase(), canvas.current.width / 4, (canvas.current.height / (isMobile ? 6 : 4)) + canvas.current.height / 12)
    texture = new THREE.Texture(canvas.current)
    texture.needsUpdate = true

    return texture
  } else {
    texture = new THREE.VideoTexture(video.current)
    texture.minFilter = THREE.LinearFilter
    texture.magFilter = THREE.LinearFilter
    texture.format = THREE.RGBFormat

    return texture
  }
}

const Boxes = ({ video, canvas, text, type }) => {
  const playPromise = video.current.play()
  const { isMobile } = useDeviceDetect()

  if (playPromise !== undefined) {
    playPromise.then(_ => true).catch(error => console.log(error))
  }

  const group = new THREE.Object3D()
  const { camera } = useThree()

  const [texture, setTexture] = useState(loadTexture(video, canvas, text, type, isMobile))

  useEffect(() => {
    document.fonts.load('20px "SangBleuSans"').then(setTexture(loadTexture(video, canvas, text, type)))
  }, [text, canvas, type, video])

  const visualSize = { width: 1920, height: 1080 }
  const xGrid = Math.ceil(window.innerWidth / Math.floor(window.innerWidth / 18))
  const yGrid = 1
  const unitX = 1 / xGrid
  const unitY = 1 / yGrid
  const xSize = ((visualSize.width / 2) / xGrid) / 1.3
  const ySize = ((visualSize.height / 2) / yGrid) / 1.3
  const parameters = { color: 0xffffff, map: texture }

  for (let i = 0; i < xGrid; i++) {
    for (let j = 0; j < yGrid; j++) {
      const offsetX = i
      const offsetY = j

      const geometry = new THREE.BoxGeometry(xSize, ySize, 0)
      const material = new THREE.MeshLambertMaterial(parameters)
      const mesh = new THREE.Mesh(geometry, material)

      mesh.position.x = (i - xGrid / 2) * xSize
      mesh.position.y = (j - yGrid / 2) * ySize
      mesh.position.z = 0
      mesh.scale.x = 1
      mesh.scale.y = 1
      mesh.scale.z = 1

      changeUvs(geometry, unitX, unitY, offsetX, offsetY)
      group.add(mesh)
    }
  }

  group.children.forEach((val) => {
    val.position.z = 0
    val.position.y = Math.random() * 20
    val.lookAt(camera.position)
    // val.rotation.y = Math.random() * 0.4
  })

  const simplex = new SimplexNoise(Math.random)
  const frequence = 0.01
  const amplitude = 0.5
  const slowRatio = 100
  let i = 0

  useFrame(() => {
    i++
    group.children.forEach((mesh) => {
      mesh.position.z += simplex.noise4D(mesh.position.x * frequence, mesh.position.y * frequence, mesh.position.z * frequence, i / slowRatio) * amplitude
      // mesh.position.y += (simplex.noise4D(mesh.position.x * frequence, mesh.position.y * frequence, mesh.position.z * frequence, i / slowRatio) * amplitude) / 2
    })

    // camera.position.x += (mouse.current[0] - camera.position.x) * 0.02
    // camera.position.y += (-mouse.current[1] - camera.position.y) * 0.02
    // camera.lookAt(scene.position)
  })

  return (<primitive object={group} position={[20, 0, 0]} />)
}

const Cover = (props) => {
  // const mouse = useRef([0, 0])
  // const onMouseMove = useCallback(({ clientX: x, clientY: y }) => {
  //   mouse.current = [(x - window.innerWidth / 2) * 0.1, (y - window.innerHeight / 2) * 0.1]
  // }, [])
  const { isMobile } = useDeviceDetect()
  const videoRef = useRef()
  const canvasRef = useRef()

  return (
    <CanvasContainer>
      <video
        style={{ display: 'none' }}
        ref={videoRef}
        playsInline
        muted
        loop
        autoPlay
        src={props.videoSrc}
      />
      <canvas ref={canvasRef} style={{ visibility: 'hidden', display: 'none' }} />
      <Canvas
        pixelRatio={Math.min(2, isMobile ? window.devicePixelRatio : 1)}
        resize={{ scroll: false }}
        camera={{ position: [0, 0, 600], far: 15000, fov: 40 }}
        // onMouseMove={onMouseMove}
      >
        <ambientLight intensity={props.type === 'text' ? 0.35 : 0.6} />
        <Boxes video={videoRef} canvas={canvasRef} {...props} />
      </Canvas>
    </CanvasContainer>
  )
}

export default Cover
