import React from 'react'
import LazyLoad from 'react-lazyload'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

// Components
import { PlaceHolder } from '../Loader'

const VideoContainer = styled.div`
  display: grid;

`

const VideoElement = styled.video`
  height: ${props => props.height};
  width: 100%;
  top: 0;
  padding: none;
  background-color: #101B1D;

  @media (max-width: 640px) {
    height: 33vh;
  }
`

const Video = (props) => {
  const onMouseOver = (e) => props.autoPlay ? false : playCheck(e)
  const onMouseOut = (e) => props.autoPlay ? false : e.target.pause()

  const playCheck = (e) => {
    const video = e.target
    var playPromise = video.play()

    if (playPromise !== undefined) {
      playPromise.then(_ => {
        // Automatic playback started!
        // Show playing UI.
        video.play()
      })
        .catch(error => {
        // Auto-play was prevented
        // Show paused UI.
          console.log(error)
          video.pause()
        })
    }
  }

  return (
    <VideoContainer>

      <LazyLoad
        height={props.height}
        once
        placeholder={<PlaceHolder height={props.height} />}
      >

        <VideoElement
          {...props}
          webkit-playsinline
          playsInline
          muted
          loop
          onMouseOver={e => onMouseOver(e)}
          onMouseOut={e => onMouseOut(e)}
        >
          <source src={props.src} type='video/mp4' />
          {/* <source src={video} type='video/ogg' /> */}
        </VideoElement>
      </LazyLoad>

    </VideoContainer>

  )
}

export default Video

Video.propTypes = {
  autoPlay: PropTypes.bool,
  src: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired
}
