import React, { useContext } from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'

// Components
import { Loader } from '../Loader'
import { Grid } from '../Grids'
import { Paragraph1 } from '../Texts'
import { H4 } from '../../components/Titles'

// Hooks
import useData from '../../hooks/useData'
import useDeviceDetect from '../../hooks/useDeviceDetect'

// Contexts
import LangContext from '../../contexts/LangContext'

// Assets
import icoClose from '../../assets/icons/ico.close.svg'

const ModalContainer = styled.div`
    width: 80vW;
    max-width: 1000px;
    z-index: 99;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #101B1D;

    @media (max-width: 640px) {
        width: 100vW;
        padding: 2rem;

        .colophonTitle {
          margin-top: 2rem;
        }

        p {
          margin-top: 4rem;
        }
    }
`

const CloseButton = styled.div`
    margin: 4rem;
    float: right;
    cursor: pointer;
    width: 21px;
    height: 21px;
    background-color: white;
    -webkit-mask-image: url(${icoClose});
    mask-image: url(${icoClose});

    @media (max-width: 640px) {
      margin: 2rem 4rem;
  }
`

const ColophonContent = () => {
  const { lang } = useContext(LangContext)
  const [data, isLoaded] = useData('/Colophon', lang)
  const { isMobile } = useDeviceDetect()

  const renderHTMLContent = (ref, element) => {
    return data.filter(d => d.ref === ref).map(d => d[element])
  }

  const render = () => {
    return (
      <Grid columns='1fr 4fr' rowGap='4rem' colGap='10rem' style={{ padding: isMobile ? '4rem' : '10vh 2vw 10vh 10vw' }}>
        <H4 className='colophonTitle' dangerouslySetInnerHTML={{ __html: renderHTMLContent('page-title-01', 'content') }}/>
        <Paragraph1 dangerouslySetInnerHTML={{ __html: renderHTMLContent('text-01', 'content') }} />
      </Grid>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

const Modal = ({ hide }) => {
  return (
    <ModalContainer>
      <CloseButton data-dismiss='modal' onClick={hide}>
        <span>Close</span>
      </CloseButton>
      <ColophonContent />
    </ModalContainer>
  )
}

const Portal = ({ isShowing, hide }) => isShowing ? ReactDOM.createPortal(<Modal isShowing hide={hide} />, document.body) : null

export default Portal
