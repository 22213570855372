import styled from '@emotion/styled'

export const Paragraph1 = styled.p`
  font-family: 'Inter';
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 160%;
  opacity: 0.6;
`

export const Paragraph2 = styled.p`
  font-family: 'Inter';
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 160%;
  opacity: 0.6;
`

export const Paragraph3 = styled.p`
  font-family: 'SangBleuSans';
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 3.4rem;
  font-weight: 400;
  line-height: 130%;
`

export const Quote = styled.p`
  font-family: 'SangBleuSans';
  font-size: 3.4rem;
  font-weight: 200;
  line-height: 140%;
  text-align: center;
  max-width: 40ch;
  justify-self: center;
  margin: 4rem 0;

  &:before {
    content: "—";
    padding-bottom: 2rem;
    display: block;
    font-size: 4.2rem;
  }

  &:after {
    content: "—";
    display: block;
    font-size: 4.2rem;
    padding-top: 2rem;
  }
`
