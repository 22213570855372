import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const Grid = styled.div((
  {
    cols = 'auto',
    rows = 'auto',
    colGap = '0',
    rowGap = '0',
    height = 'auto',
    pads = '0',
    margin = '0',
    justify = 'auto',
    align = 'auto',
    col = 'auto',
    row = 'auto',
    mobileCols = '1fr'
  }) =>
  css`
    display: grid;
    grid-template-columns: ${cols};
    grid-template-rows: ${rows};
    grid-column-gap: ${colGap};
    grid-row-gap: ${rowGap};
    padding: ${pads};
    margin: ${margin};
    justify-items: ${justify};
    align-items: ${align};
    height: ${height};

    > * {
      grid-column: ${col};
      grid-row: ${row};

      @media (max-width: 640px) {
        grid-column: auto;
        grid-row: auto;
      }
    }

    @media (max-width: 640px) {
      grid-template-columns: ${mobileCols};
      padding: 0;
    }
  `
)
