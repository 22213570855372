import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'

// Components
import LangSelector from '../LangSelector'
import { Grid } from '../Grids'
import { Loader } from '../Loader'
import { BurgerMenu, Menu } from '../BurgerMenu'

// Contexts
import LangContext from '../../contexts/LangContext'

// Hooks
import useData from '../../hooks/useData'
import useDeviceDetect from '../../hooks/useDeviceDetect'

// Assets
import logo from '../../assets/icons/logo.svg'

const Logo = styled.div`
  &:before {
    content: " ";
    display: inline-block;
    width: 44px;
    height: 68px;
    background-color: white;
    background: url(${logo}) no-repeat bottom left;
  }
`

const MenuLink = styled(NavLink)`
  text-transform: uppercase;
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  display: block;

  &:hover {
    text-decoration: none;
    color: #C5B99A;
  }

  &.active {
    padding-top: 3rem;
    &:after {
      padding-top: 1.5rem;
      text-align: center;
      content: "—";
      display: block;
      font-size: 1.5rem;
    }
  }
`

const Nav = () => {
  const { lang } = useContext(LangContext)
  const [data, isLoaded] = useData('/_menu', lang)
  const { isMobile } = useDeviceDetect()

  const [open, setOpen] = useState(false)

  const linksList = data.filter(d => d.ref === 'nav.internal').map((d, i) => {
    return (
      <MenuLink exact key={i} to={d.route}>{d.content}</MenuLink>
    )
  })

  const render = () => {
    return (
      <Grid cols='repeat(3, 1fr)' pads='2rem 5vw' mobileCols='repeat(3, 1fr)'>
        <Grid cols='repeat(5, auto)' align='center' colGap='2rem' justify='left'>
          {isMobile
            ? <>
              <BurgerMenu open={open} setOpen={setOpen} aria-controls='main-menu' />
              <Menu open={open} setOpen={setOpen} id='main-menu' />
            </>
            : linksList}
        </Grid>
        <Grid align='center' justify='center'><NavLink exact to='/'><Logo /></NavLink></Grid>
        <Grid align='center' justify='right'><LangSelector /></Grid>
      </Grid>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default Nav
